import classes from "./users.module.css";
import { useUsersLogic } from "./useUsersLogic";
import MUITable from "../../components/MUITable/MUITable";
import AddUser from "../../components/Modal/UserAccount/AddUser";
import Notification from "../../components/Notification";
import Loader from "../../components/Loader/Loader";

function Users() {
  const { data, isError, error, isLoading, columns, notification } =
    useUsersLogic();
  const { severity, message, open, handleClose } = notification;

  return (
    <div>
      <div className={classes.header}>
        <AddUser />
      </div>
      <MUITable
        data={data}
        columns={columns}
        rowsOnDisplay={10}
        noMatch={
          isLoading ? (
            <Loader />
          ) : isError ? (
            <p>{error?.message}</p>
          ) : (
            "No record found"
          )
        }
      />
      <Notification
        severity={severity}
        message={message}
        openNotification={open}
        handleClose={handleClose}
      />
    </div>
  );
}

export default Users;
