import CircularProgress from "@mui/material/CircularProgress";
import { Button } from "@mui/material";
import { useRefundLogic } from "./useRefundLogic";
import MUITable from "../../components/MUITable/MUITable";
import Notification from "../../components/Notification";
import DateRangePicker from "../../components/DateRangePicker/DateRangePicker";
import classes from "./refund.module.css";
import Loader from "../../components/Loader/Loader";
import AddRefund from "../../components/Modal/Refunds/AddRefund";

function Refund() {
  const {
    isError,
    isLoading,
    error,
    notification,
    getFromAndToDate,
    columns,
    refundRecords,
    isSuccess,
    handleRefundFetch,
    duration,
    queryKey,
    departmentOptions,
  } = useRefundLogic();
  const { severity, message, open, handleClose } = notification;

  return (
    <div>
      <div className={classes.header}>
        <DateRangePicker getFromAndToDate={getFromAndToDate} />
        <Button
          className={classes.button}
          disabled={isSuccess}
          onClick={handleRefundFetch}
        >
          Submit query
          {isLoading && <CircularProgress color="success" size={15} />}
        </Button>
        <AddRefund queryKey={queryKey} departmentOptions={departmentOptions} />
      </div>
      <MUITable
        columns={columns}
        data={refundRecords}
        noMatch={
          isLoading ? (
            <Loader />
          ) : isError ? (
            <p>{error?.message}</p>
          ) : !isSuccess ? (
            `Bɛɛman, abeg nawai hit the submit button to get from ${duration}`
          ) : (
            `No record found for ${duration}`
          )
        }
        rowsOnDisplay={10}
      />
      <Notification
        severity={severity}
        message={message}
        openNotification={open}
        handleClose={handleClose}
      />
    </div>
  );
}

export default Refund;
