import { useLocation } from "react-router-dom";
import { useUserContext } from "../../context/context";
import { routes } from "../../utils/constants/route";
import { useEffect } from "react";

export const useSidebarLogic = () => {
  const { handleHeaderText } = useUserContext();

  const { pathname } = useLocation();

  const { userdetails } = useUserContext();

  useEffect(() => {
    handleHeaderText(pathname);
  }, [pathname, handleHeaderText]);

  const navItems = routes.USER.filter((item) => {
    if (userdetails.is_admin === 1) {
      return item;
    }
    return item.level === "user";
  });

  return { navItems, handleHeaderText, pathname };
};
