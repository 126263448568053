import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useFetches } from "../../hooks/useFetches";
import moment from "moment";
import { useQueryClient } from "react-query";
import { useUserContext } from "../../context/context";

export function useHomeLogic() {
  const currentDate = moment();
  const count = 1;
  const [startDate, setStartDate] = useState(
    moment().subtract(count, "week").format("YYYY-MM-DD")
  );
  const [duration, setDuration] = useState("week");
  const queryClient = useQueryClient();
  const { userdetails, notification } = useUserContext();

  const listOfEndpoints = useMemo(() => {
    return [
      {
        queryKey: [
          userdetails.is_admin === 1 ? "refunds/all/stats" : "refunds/stats",
        ],
        field: "refundStats",
      },
      {
        queryKey: [
          userdetails.is_admin === 1
            ? `refunds/all?start=${startDate}&end=${currentDate.format(
                "YYYY-MM-DD"
              )}`
            : `refunds?start=${startDate}&end=${currentDate.format(
                "YYYY-MM-DD"
              )}`,
        ],
        field: "refunds",
      },
    ];
  }, [startDate, currentDate, userdetails.is_admin]);

  const queryResult = useFetches(listOfEndpoints, true, {}, 1800000, 1800000);
  const { active, inactive, total } = queryResult[0].isSuccess
    ? queryResult[0].data?.data?.data.reduce(
        (acc, { status, count }) => {
          const category =
            status === "Pending" || status === "Processing"
              ? "active"
              : "inactive";
          acc[category] += count;
          acc.total += count;
          return acc;
        },
        { active: 0, inactive: 0, total: 0 }
      )
    : { active: 0, inactive: 0, total: 0 };

  const [refundRecords, totalAmount] = queryResult[1].isSuccess
    ? queryResult[1].data?.data?.data.reduce(
        (acc, record) => {
          acc[0].push(record);
          acc[1] = acc[1] + record.amount;
          return acc;
        },
        [[], 0]
      )
    : [[], 0];

  //
  const handleChange = (event) => {
    setDuration(event.target.value);
    setStartDate(
      currentDate.subtract(count, event.target.value).format("YYYY-MM-DD")
    );
  };
  const listOfEndpointsRef = useRef(listOfEndpoints);

  useEffect(() => {
    listOfEndpointsRef.current = listOfEndpoints;
  }, [listOfEndpoints]);

  const inValidateQuery = useCallback(() => {
    listOfEndpointsRef.current.forEach((endpoint) => {
      queryClient.invalidateQueries(endpoint.queryKey);
    });
  }, [queryClient]);

  useEffect(() => {
    inValidateQuery();
  }, [duration, queryClient, inValidateQuery]);

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      name: "username",
      label: "Username",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "department",
      label: "Department",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "item",
      label: "Item",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "time_created",
      label: "Requested Time",
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      name: "time_updated",
      label: "Time Updated",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "image",
      label: "Image",
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    // {
    //   name: "actions",
    //   label: "Actions",
    //   options: {
    //     sort: false,
    //     customBodyRender: (_value, tableMeta, _updateValue) => {
    //       return (
    //         <div>
    //           <ViewRefundRecord rowData={tableMeta.rowData} />
    //           <EditRefundRecord
    //             rowData={tableMeta.rowData}
    //             listOfEndpoints={listOfEndpoints}
    //           />
    //           <DeleteRefundRecord
    //             rowData={tableMeta.rowData}
    //             listOfEndpoints={listOfEndpoints}
    //           />
    //         </div>
    //       );
    //     },
    //   },
    // },
  ];

  return {
    //datatable fields
    columns,
    refundRecords,
    //
    duration,
    //stats figures
    active,
    inactive,
    total,
    //
    queryResult,
    totalAmount,
    handleChange,
    notification,
    //
    listOfEndpoints,
  };
}
