import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import useUserModalLogic from "./useUserModalLogic";
import classes from "../modal.module.css";

//
function ViewUserDetails({ rowData }) {
  const { handlers, state } = useUserModalLogic();
  const { open } = state;
  const { handleClickOpen, handleClose } = handlers;

  return (
    <>
      <IconButton onClick={handleClickOpen}>
        <VisibilityIcon className={classes.view} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="view-record"
        aria-describedby="view-refund-record"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="view-record">
          {`Details of ${rowData[1]}'s account`}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            {/* username & email */}
            <Grid item xs={12} md={6}>
              <i>
                <strong>Email</strong>
              </i>
              <div>{rowData[2]}</div>
            </Grid>
            <Grid item xs={12} md={6}>
              <i>
                <strong>Msisdn</strong>
              </i>
              <div>{rowData[3]}</div>
            </Grid>
            <Grid item xs={12} />
            {/* Staus and Admin */}
            <Grid item xs={12} md={6}>
              <i>
                <strong>Status</strong>
              </i>
              <div>{rowData[4]}</div>
            </Grid>
            <Grid item xs={12} md={6}>
              <i>
                <strong>Admin</strong>
              </i>
              <div>{rowData[5]}</div>
            </Grid>
            <Grid item xs={12} />

            {/* timestamps */}
            <Grid item xs={12} md={6}>
              <i>
                <strong>Requested Time</strong>
              </i>
              <div>
                {new Intl.DateTimeFormat("en-Us", {
                  dateStyle: "full",
                  timeStyle: "full",
                }).format(new Date(rowData[6]))}
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <i>
                <strong>Time Updated</strong>
              </i>
              <div>
                {new Intl.DateTimeFormat("en-Us", {
                  dateStyle: "full",
                  timeStyle: "full",
                }).format(new Date(rowData[7]))}
              </div>
            </Grid>
            <Grid item xs={12} />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ViewUserDetails;
