import {
  TextField,
  MenuItem,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
} from "@mui/material";
import classes from "./forms.module.css";

export function TextInput(props) {
  const { register, error, name, label, autoFocus, id = "username" } = props;
  return (
    <>
      <label htmlFor={id} className={classes.label_field}>
        {label[0].toUpperCase() + label.slice(1)}
      </label>
      <TextField
        {...register(name, {
          required: {
            value: true,
            message: `${name} is required`,
          },
          minLength: {
            value: 3,
            message: "Must be more than 3 characters",
          },
          pattern: {
            value: /^[a-zA-Z0-9@_-\s]*$/,
            message: "Unacceptable character(s) found",
          },
        })}
        error={!!error}
        fullWidth
        // label={label}
        id={id}
        autoComplete="username"
        helperText={error?.message}
        className={classes.form__input}
        autoFocus={autoFocus}
        style={{ marginTop: "2px" }}
      />
    </>
  );
}

export function EmailInput(props) {
  const { register, error, name, label, autoFocus, id = "email" } = props;
  return (
    <>
      <label htmlFor={id} className={classes.label_field}>
        {label[0].toUpperCase() + label.slice(1)}
      </label>
      <TextField
        {...register(name, {
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            message: "Invalid email address",
          },
          required: {
            value: true,
            message: `${name} is required`,
          },
          minLength: {
            value: 12,
            message: "Invalid email address. Too short",
          },
          maxLength: {
            value: 45,
            message: "Invalid email address. Too long",
          },
        })}
        error={!!error}
        fullWidth
        // label={label}
        id={id}
        autoComplete="email"
        helperText={error?.message}
        className={classes.form__input}
        autoFocus={autoFocus}
        style={{ marginTop: "2px" }}
      />
    </>
  );
}

export function PasswordInput(props) {
  const {
    register,
    error,
    name,
    label,
    id = "password",
    required = true,
  } = props;
  return (
    <>
      <label htmlFor={id} className={classes.label_field}>
        {label}
      </label>
      <TextField
        {...register(name, {
          required: {
            value: required,
            message: `${name} is required`,
          },
          minLength: {
            value: 8,
            message: "Must be more than 8 characters",
          },
          pattern: {
            value: /^(?=.*[a-zA-Z])(?=.*\d)[!@#$%^&*+(){ }?></a-zA-Z\d]{8,}$/,
            message: "Must be alphanumeric with no unacceptable character(s)",
          },
        })}
        error={!!error}
        fullWidth
        type="password"
        // label={label}
        id={id}
        helperText={error?.message}
        autoComplete="current-password"
        className={classes.form__input}
        style={{ marginTop: "2px" }}
      />
    </>
  );
}

export function NumberInput(props) {
  const { register, error, name, label, id = "number", minLength = 3 } = props;
  return (
    <>
      <label htmlFor={id} className={classes.label_field}>
        {label}
      </label>
      <TextField
        {...register(name, {
          required: {
            value: true,
            message: `${name} is required`,
          },
          minLength: {
            value: minLength,
            message: "Must be more than 3 characters",
          },
          maxLength: {
            value: 13,
            message: "Must not be more than 13 characters",
          },
          pattern: {
            value: /^[a-zA-Z0-9@_-\s]*$/,
            message: "Unacceptable character(s) found",
          },
        })}
        error={!!error}
        margin="normal"
        fullWidth
        // label={label}
        id={id}
        autoComplete={id}
        helperText={error?.message}
        type="number"
        className={classes.form__input}
      />
    </>
  );
}

export function SelectInput(props) {
  const { register, error, name, options, id, label } = props;
  return (
    <>
      <label htmlFor={id} className={classes.label_field}>
        {label}
      </label>
      <TextField
        {...register(name, {
          required: {
            value: true,
            message: `${name} is required`,
          },
          pattern: {
            value: /^[a-zA-Z0-9@_-\s]*$/,
            message: "Unacceptable character(s) found",
          },
        })}
        error={!!error}
        margin="normal"
        fullWidth
        autoComplete="username"
        id={name}
        helperText={error?.message}
        select
        defaultValue={options[0]?.id}
        className={classes.form__input}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
}

export function SelectRefundInput(props) {
  const { register, error, name, options, id, label, defaultValue, disabled } =
    props;
  return (
    <>
      <label htmlFor={id} className={classes.label_field}>
        {label}
      </label>
      <TextField
        {...register(name, {
          required: {
            value: true,
            message: `${name} is required`,
          },
          pattern: {
            value: /^[a-zA-Z0-9@_-\s]*$/,
            message: "Unacceptable character(s) found",
          },
        })}
        error={!!error}
        margin="normal"
        fullWidth
        autoComplete="username"
        id={name}
        helperText={error?.message}
        select
        defaultValue={defaultValue?.id}
        className={classes.form__input}
        disabled={disabled}
      >
        {options?.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
}

//Radio selection
export function SelectRadioOption(props) {
  const { register, name, label, options, defaultValue } = props;
  return (
    <div className={classes.select_field}>
      <FormControl>
        <FormLabel id="demo-radio-buttons-group-label">{label}</FormLabel>
        <RadioGroup
          row
          aria-labelledby="row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          defaultValue={defaultValue}
        >
          {options.map(({ value, label }) => (
            <FormControlLabel
              key={label}
              value={value}
              control={<Radio {...register(name)} />}
              label={label}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
}

export function TextAreaInput(props) {
  const { register, error, name, id, label } = props;
  return (
    <TextField
      {...register(name, {
        required: {
          value: true,
          message: `${name} is required`,
        },
        minLength: {
          value: 3,
          message: "Must be more than 3 characters",
        },
        pattern: {
          value: /^[A-Za-z0-9\s.,\-?"()]*$/,
          message: "Unacceptable character(s) found",
        },
      })}
      error={!!error}
      margin="normal"
      fullWidth
      id={id}
      label={label}
      multiline
      minRows={3}
      maxRows={6}
      helperText={error?.message}
      className={classes.form__input}
    />
  );
}
