import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useUserModalLogic from "./useUserModalLogic";
import classes from "../modal.module.css";
import { Box } from "@mui/material";
import {
  EmailInput,
  NumberInput,
  PasswordInput,
  SelectRadioOption,
  TextInput,
} from "../../Form/FormsInputs";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";

//
function EditUser({ rowData }) {
  const [id, username, fullname, email, msisdn, status, is_admin] = rowData;
  const { state, handlers, fetchResponse, statusOptions, adminOptions } =
    useUserModalLogic({
      id,
      fullname,
      username,
      email,
      msisdn,
      status,
      is_admin,
    });
  const { register, errors, open } = state;
  const { isLoading } = fetchResponse;
  const { handleSubmit, onSubmit, handleClose, handleClickOpen } = handlers;

  return (
    <>
      <IconButton onClick={handleClickOpen}>
        <EditIcon className={classes.edit} />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="edit-user-title"
        aria-describedby="edit-user-description"
      >
        <Box
          component="form"
          id="edituserform"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 1 }}
          className={classes.container}
        >
          <DialogTitle id="edit-user-title">{`Edit ${rowData[1]}'s useraccount`}</DialogTitle>
          <DialogContent>
            <TextInput
              register={register}
              name="fullname"
              label="Fullname*"
              id="accountfullname"
              error={errors?.fullname}
              autoFocus
            />
            <EmailInput
              register={register}
              name="email"
              id="accountemail"
              label="User email*"
              error={errors?.email}
            />
            <NumberInput
              register={register}
              name="msisdn"
              id="msisdn"
              label="Contact number*"
              error={errors?.msisdn}
            />
            <PasswordInput
              register={register}
              name="password"
              id="accountpassword"
              label="Password"
              required={false}
              error={errors?.password}
            />
            <SelectRadioOption
              register={register}
              name="status"
              id="status"
              label="Status"
              defaultValue={status}
              options={statusOptions}
            />
            <SelectRadioOption
              register={register}
              name="is_admin"
              id="is_admin"
              label="Admin"
              defaultValue={is_admin}
              options={adminOptions}
            />
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              disabled={isLoading}
              className={classes.button}
            >
              Proceed&nbsp;
              <EditIcon />
            </Button>
            <Button onClick={handleClose} autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}

export default EditUser;
