import HomeIcon from "../../assets/images/svg/HomeIcon";
import RevenueIcon from "../../assets/images/svg/RevenueIcon";
import ServiceIcon from "../../assets/images/svg/ServiceIcon";

export const routes = {
  USER: [
    {
      id: "",
      level: "user",
      name: "Home",
      Icon: (props) => <HomeIcon {...props} />,
    },
    {
      id: "users",
      level: "admin",
      name: "Users",
      Icon: (props) => <ServiceIcon {...props} />,
    },
    {
      id: "refunds",
      level: "user",
      name: "Refunds",
      Icon: (props) => <RevenueIcon {...props} />,
    },
  ],
};
