import MUIDataTable from "mui-datatables";

function MUITable({
  data,
  columns,
  title,
  rowsOnDisplay = 5,
  noMatch = "Sorry, no matching records found",
}) {
  const options = {
    textLabels: {
      body: {
        noMatch,
      },
    },
    selectableRows: "none",
    rowsPerPage: rowsOnDisplay,
    rowsPerPageOptions: [5, 10, 30, 50, 100],
    print: false,
    viewColumns: false,
    filter: false,
  };

  return (
    <div>
      <MUIDataTable
        title={title}
        data={data}
        columns={columns}
        options={options}
      />
    </div>
  );
}

export default MUITable;
