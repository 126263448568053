import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useModalLogicfrom from "./useModalLogic";
import classes from "../modal.module.css";
import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import {
  NumberInput,
  SelectRefundInput,
  TextAreaInput,
  TextInput,
} from "../../Form/FormsInputs";
//
function EditRefund({ rowData, queryKey, is_admin, departmentOptions = [] }) {
  const [
    id,
    username,
    department,
    item,
    amount,
    description,
    status,
    time_created,
    time_updated,
  ] = rowData;
  const defaultValue = departmentOptions.find(
    (departmentOption) => departmentOption.name === department
  );
  const { state, handlers, handleOpen, isLoading, statusOptions } =
    useModalLogicfrom(
      queryKey,
      {
        id,
        username,
        department,
        item,
        amount,
        description,
        status,
        time_created,
        time_updated,
      },
      is_admin
    );
  const { register, errors, open } = state;

  const { handleSubmit, onSubmit, handleClose } = handlers;
  const disabled =
    status.toLowerCase() === "rejected" || status.toLowerCase() === "completed";
  return (
    <>
      <IconButton disabled={disabled} onClick={handleOpen}>
        {is_admin ? (
          <EditIcon className={classes.edit} />
        ) : (
          <EditIcon
            className={disabled ? classes.edit_disabled : classes.edit}
          />
        )}
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="edit-refund-title"
        aria-describedby="edit-refund-description"
      >
        <Box
          component="form"
          id="editrefundform"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 1 }}
          className={classes.container}
        >
          <DialogTitle id="edit-refund-title">Edit Refund</DialogTitle>
          <DialogContent>
            <TextInput
              register={register}
              name="item"
              label="Name of item*"
              id="item"
              error={errors?.item}
              autoFocus
            />
            <NumberInput
              register={register}
              name="amount"
              id="amount"
              minLength={1}
              label="Amount*"
              error={errors?.amount}
            />
            <SelectRefundInput
              register={register}
              name="department_id"
              id="department_id"
              label="Department"
              options={departmentOptions}
              defaultValue={defaultValue}
            />
            <SelectRefundInput
              register={register}
              name="status"
              id="status"
              label="Status"
              options={statusOptions}
              defaultValue={{ id: status, name: status }}
              disabled={is_admin ? false : true}
            />
            <TextAreaInput
              register={register}
              name="description"
              id="description"
              label="Description*"
              error={errors?.description}
            />
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              disabled={isLoading}
              className={classes.button}
            >
              Proceed&nbsp;
              <CurrencyExchangeIcon />
            </Button>
            <Button onClick={handleClose} autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}

export default EditRefund;
