import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useUserModalLogic, { adminOptions } from "./useUserModalLogic";
import classes from "../modal.module.css";
import { Box } from "@mui/material";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import {
  EmailInput,
  NumberInput,
  PasswordInput,
  SelectRadioOption,
  TextInput,
} from "../../Form/FormsInputs";

//
function AddUser() {
  const { state, handlers, fetchResponse } = useUserModalLogic();
  const { register, errors, open } = state;
  const { isLoading } = fetchResponse;
  const { handleSubmit, onSubmit, handleClose, handleClickOpen } = handlers;

  return (
    <>
      <Button className={classes.button} onClick={handleClickOpen}>
        Add New User
        <PersonAddAltIcon />
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="add-user-title"
        aria-describedby="ass-user-description"
      >
        <Box
          component="form"
          id="adduserform"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 1 }}
          className={classes.container}
        >
          <DialogTitle id="add-user-title">Add a new useraccount</DialogTitle>
          <DialogContent>
            <TextInput
              register={register}
              name="fullname"
              label="Fullname*"
              id="accountfullname"
              error={errors?.fullname}
              autoFocus
            />

            <TextInput
              register={register}
              name="username"
              id="accountname"
              label="Username*"
              error={errors?.username}
            />
            <EmailInput
              register={register}
              name="email"
              id="accountemail"
              label="User email*"
              error={errors?.email}
            />
            <NumberInput
              register={register}
              name="msisdn"
              id="msisdn"
              label="Contact number*"
              error={errors?.msisdn}
            />
            <PasswordInput
              register={register}
              name="password"
              id="accountpassword"
              label="Password"
              error={errors?.password}
            />
            <SelectRadioOption
              register={register}
              name="is_admin"
              id="is_admin"
              label="Admin"
              defaultValue={0}
              options={adminOptions}
            />
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              disabled={isLoading}
              className={classes.button}
            >
              Add User&nbsp;
              <PersonAddAltIcon />
            </Button>
            <Button onClick={handleClose} autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}

export default AddUser;
