import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import classes from "../modal.module.css";
import placeholder from "../../../assets/images/placeholder.png";

function ViewRefundRecord({ rowData }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleClickOpen}>
        <VisibilityIcon className={classes.view} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="view-record"
        aria-describedby="view-refund-record"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="view-record">
          {`Details of ${rowData[1]}'s refund record`}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            {/* username & department */}
            <Grid item xs={12} md={6}>
              <i>
                <strong>Account name</strong>
              </i>
              <div>{rowData[1]}</div>
            </Grid>
            <Grid item xs={12} md={6}>
              <i>
                <strong>Department</strong>
              </i>
              <div>{rowData[2]}</div>
            </Grid>
            <Grid item xs={12} />
            {/* item and amount */}
            <Grid item xs={12} md={6}>
              <i>
                <strong>item</strong>
              </i>
              <div>{rowData[3]}</div>
            </Grid>
            <Grid item xs={12} md={6}>
              <i>
                <strong>Amount</strong>
              </i>
              <div>{rowData[4]}</div>
            </Grid>
            <Grid item xs={12} />
            {/* description & status */}
            <Grid item xs={12} md={6}>
              <i>
                <strong>Description</strong>
              </i>
              <div>{rowData[5]}</div>
            </Grid>
            <Grid item xs={12} md={6}>
              <i>
                <strong>Status</strong>
              </i>
              <div>{rowData[6]}</div>
            </Grid>
            <Grid item xs={12} />
            {/* timestamps */}
            <Grid item xs={12} md={6}>
              <i>
                <strong>Requested Time</strong>
              </i>
              <div>
                {new Intl.DateTimeFormat("en-Us", {
                  dateStyle: "full",
                  timeStyle: "full",
                }).format(new Date(rowData[7]))}
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <i>
                <strong>Time Updated</strong>
              </i>
              <div>
                {new Intl.DateTimeFormat("en-Us", {
                  dateStyle: "full",
                  timeStyle: "full",
                }).format(new Date(rowData[8]))}
              </div>
            </Grid>
            <Grid item xs={12} />
            {/* image */}
            <Grid item xs={12}>
              <i>
                <strong>Image/Evidence</strong>
              </i>
            </Grid>
            <Grid item xs={12}>
              <img
                alt="Evidence of item"
                src={rowData[9] || placeholder}
                className={classes.image}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ViewRefundRecord;
