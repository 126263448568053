import { Grid, FormControl, Select, MenuItem } from "@mui/material";
import CustomCard from "../../components/Card/CustomCard";
import classes from "./home.module.css";
import { useHomeLogic } from "./useHomeLogic";
import MUITable from "../../components/MUITable/MUITable";
import Notification from "../../../src/components/Notification";

export default function Home() {
  const {
    columns,
    refundRecords,
    duration,
    totalAmount,
    handleChange,
    total,
    active,
    inactive,
    notification,
  } = useHomeLogic();
  const { open, handleClose, message, severity } = notification;
  return (
    <Grid container spacing={5}>
      <Grid item xs={6} sm={3}>
        <CustomCard
          header="Active refunds"
          helperHeader="Current refunds in processing"
          color="#b8b5ff"
          iconColor="#6CF7A6"
          count={active}
          border="0px"
          // arrow={true}
          duration="&nbsp;"
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <CustomCard
          header="Refunds completed"
          helperHeader="Total completed refunds"
          color="#FDF2C1"
          iconColor="#FAB877"
          count={inactive}
          border="0px"
          // arrow={true}
          duration="&nbsp;"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CustomCard
          header="Total Refunds"
          helperHeader="All refunds for this account"
          color="#FFFFFF"
          count={total}
          border="1px solid #ebeaed"
          duration="&nbsp;"
        />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.graphHeader}>
          <div className={classes.revenue}>Revenue Overview</div>
          <div className={classes.amountContainer}>
            <div className={classes.amount}>GHS {totalAmount}</div>
            <div className={classes.duration}>This {duration}</div>
          </div>
          <FormControl size="small" id="select-duration-form">
            <Select
              labelId="select-small-label"
              id="select-small-duration"
              value={duration}
              onChange={handleChange}
              className={classes.options}
            >
              <MenuItem value="week" id="last 7 days" name="last 7 days">
                Last 7 days
              </MenuItem>
              <MenuItem value="month" id="last 30 days" name="last 30 days">
                Last 30 days
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <MUITable data={refundRecords} columns={columns} title={""} />
      </Grid>
      <Notification
        severity={severity}
        message={message}
        openNotification={open}
        handleClose={handleClose}
      />
    </Grid>
  );
}
