import { Route, Routes } from "react-router-dom";
import PrivateRoutes from "./components/PrivateRoutes/PrivateRoutes";
import NotFound from "./pages/NotFound";
import Home from "./pages/Home/Home";
import Profile from "./pages/Profile/Profile";
import ResetPasswordForm from "./components/ResetPassword/ResetPasswordForm";
import WelcomeLayout from "./pages/WelcomeLayout/WelcomeLayout";
import LoginForm from "./components/Login/LoginForm";
import ForgotPasswordForm from "./components/ForgotPassword/ForgotPasswordForm";
import ResendPassword from "./components/ResendPassword/ResendPassword";
import Refund from "./pages/Refund/Refund";
import Users from "./pages/Users/Users";

function App() {
  return (
    <Routes>
      <Route element={<WelcomeLayout />}>
        <Route path="/login">
          <Route index element={<LoginForm />} />
          <Route
            path="/login/reset-password"
            element={<ForgotPasswordForm />}
          />
          <Route
            path="/login/reset-password-email-sent"
            element={<ResendPassword />}
          />
        </Route>
        <Route
          path="/reset-password/:keyword"
          element={<ResetPasswordForm />}
        />
      </Route>

      <Route element={<PrivateRoutes />}>
        <Route path="/">
          <Route index element={<Home />} />
          <Route path="/refunds" element={<Refund />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/users" element={<Users />} />
          <Route
            path="/services/:keyword/:servicename"
            element={<h1>to change</h1>}
          />
        </Route>
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
