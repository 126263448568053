import DeleteUser from "../../components/Modal/UserAccount/DeleteUser";
import ViewUserDetails from "../../components/Modal/UserAccount/ViewUserDetails";
import EditUser from "../../components/Modal/UserAccount/EditUser";
import { useUserContext } from "../../context/context";
import { useFetch } from "../../hooks/useFetch";
import classes from "./users.module.css";

//
export function useUsersLogic() {
  const { notification } = useUserContext();

  const queryKey = [`user/all`];

  const queryResult = useFetch(queryKey, true, {}, 1800000, 1800000);

  //
  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "username",
      label: "Username",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "fullname",
      label: "Fullname",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "msisdn",
      label: "Number",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        sort: true,
        filter: true,
        customBodyRender: (value) => {
          if (value === 1) {
            return <div className={classes.active}> Active </div>;
          }
          return <div className={classes.inactive}> Inactive </div>;
        },
      },
    },
    {
      name: "is_admin",
      label: "Admin",
      options: {
        sort: true,
        filter: true,
        customBodyRender: (value) => {
          if (value === 1) {
            return <div className={classes.active}> Yes </div>;
          }
          return <div className={classes.inactive}> No </div>;
        },
      },
    },
    {
      name: "is_admin",
      label: "Admin",
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },

    {
      name: "time_created",
      label: "Time Created",
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      name: "time_updated",
      label: "Time Updated",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        sort: false,
        customBodyRender: (_value, tableMeta, _updateValue) => {
          return (
            <div>
              <ViewUserDetails rowData={tableMeta.rowData} />
              <EditUser rowData={tableMeta.rowData} />
              <DeleteUser rowData={tableMeta.rowData} />
            </div>
          );
        },
      },
    },
  ];

  return {
    data: queryResult.data?.data?.data || [],
    isError: queryResult.isError,
    error: queryResult.error,
    isLoading: queryResult.isLoading,
    isSuccess: queryResult.isSuccess,
    columns,
    notification,
  };
}
