import { green } from "@mui/material/colors";
import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import WarningIcon from "@mui/icons-material/Warning";
import classes from "../modal.module.css";
import useUserModalLogic from "./useUserModalLogic";

function DeleteUser({ rowData }) {
  const { handlers, state, fetchResponse } = useUserModalLogic();
  const { open } = state;
  const { isLoading } = fetchResponse;
  const { handleClickOpen, handleClose, handleDelete } = handlers;

  return (
    <>
      <IconButton onClick={handleClickOpen}>
        <DeleteIcon className={classes.delete} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="delete-record"
        aria-describedby="delete-refund-record"
      >
        <DialogTitle id="delete-record">
          {`Delete ${rowData[1]}'s refund record`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="delete-refund-record"
            className={classes.delete_content}
          >
            <WarningIcon fontSize="large" color="warning" />
            {`Are you sure you want to delete ${rowData[1]}'s account?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDelete(rowData[0])}>
            {" "}
            {isLoading && (
              <CircularProgress
                size={24}
                sx={{
                  color: green[500],
                  position: "absolute",
                  top: "64%",
                  left: "67%",
                }}
              />
            )}{" "}
            Yes
          </Button>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DeleteUser;
