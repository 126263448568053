import { useNavigate, useParams } from "react-router-dom";
import {
  getUserRoleCookie,
  getUsernameCookie,
  removeCookie,
} from "../../utils/auth";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { useUserContext } from "../../context/context";

export const useHeaderLogic = () => {
  const navigate = useNavigate();

  const header = useUserContext();
  const headerText = header?.headerText;
  const headerTextComponent = header?.headerTextComponent;

  const { servicename, keyword } = useParams();

  const queryClient = useQueryClient();
  const [username] = useState(() => getUsernameCookie());
  const [privilege] = useState(() => getUserRoleCookie());

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = (route) => {
    setAnchorEl(null);
    if (route === "profile") {
      navigate(route);
    }
  };

  const handleLogout = () => {
    removeCookie();
    queryClient.clear();
    navigate("/login", {
      replace: true,
    });
  };

  return {
    handleLogout,
    username,
    privilege,
    handleMenu,
    handleClose,
    anchorEl,
    headerText,
    headerTextComponent,
    servicename,
    keyword,
  };
};
