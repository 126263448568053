import Cookies from "universal-cookie";
import { Constants as K } from "./constants/main";

const cookies = new Cookies();

// Check if logged in
export const isAuthenticated = () =>
  Boolean(cookies.get(K.Cookies.API_TOKEN_KEY));

// Set cookie
export const setAuthCookie = (authToken) => {
  cookies.set(K.Cookies.API_TOKEN_KEY, authToken, { path: "/" });
};

// Set department
export const setDepartmentCookie = (department) => {
  cookies.set(K.Cookies.DEPARTMENT, department, { path: "/" });
};
// Get username
export const getUsernameCookie = () => cookies.get(K.Cookies.USERNAME);

//Get department
export const getDepartmentCookie = () => cookies.get(K.Cookies.DEPARTMENT);

// Get level
export const getUserRoleCookie = () => cookies.get(K.Cookies.PRIVILEGE);

// Set level Cookie
export const setUserRoleCookie = (roleAndId) => {
  cookies.set(K.Cookies.PRIVILEGE, roleAndId, { path: "/" });
};

// Set username Cookie
export const setUsernameCookie = (user) => {
  cookies.set(K.Cookies.USERNAME, user, { path: "/" });
};

// Set userdetails Cookie
export const setUserDetailsCookie = (userdetails) => {
  cookies.set(K.Cookies.USERDETAILS, userdetails, { path: "/" });
};

// Get username
export const getUserDetailsCookie = () => cookies.get(K.Cookies.USERDETAILS);

// Set userdetails Cookie
export const setFromAndToLocalDate = (datedetails) => {
  cookies.set(K.Cookies.DATEDETAILS, datedetails, { path: "/" });
};

// Get username
export const getFromAndToLocalDate = () => cookies.get(K.Cookies.DATEDETAILS);

//remove cookie
export function removeCookie() {
  cookies.remove(K.Cookies.API_TOKEN_KEY);
  cookies.remove(K.Cookies.PRIVILEGE);
  cookies.remove(K.Cookies.USERNAME);
  cookies.remove(K.Cookies.USERDETAILS);
  cookies.remove(K.Cookies.DEPARTMENT);
}
