import { useState } from "react";
import { useQueryClient } from "react-query";
import { usePost } from "../../../hooks/usePost";
import { useUserContext } from "../../../context/context";
import { useForm } from "react-hook-form";

function useModalLogic(queryKey, editRefundData, is_admin) {
  const statusOptions = [
    { id: "Pending", name: "Pending" },
    { id: "Processing", name: "Processing" },
    { id: "Completed", name: "Completed" },
    { id: "Rejected", name: "Rejected" },
  ];
  const queryClient = useQueryClient();
  const { notification, userdetails } = useUserContext();
  const [open, setOpen] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      item: editRefundData?.item,
      amount: editRefundData?.amount,
      department_id: editRefundData?.department_id,
      description: editRefundData?.description,
      status: editRefundData?.status,
    },
  });

  // For adding refund
  const onSubmit = (values, event) => {
    let method = "post";
    let url = "refunds";
    if (is_admin === 0) {
      delete values.status;
    }
    let dataToSend = { ...values, users_id: userdetails.id };
    if (event.target.id === "editrefundform") {
      method = "put";
      url = `refunds/${editRefundData.id}`;
      delete dataToSend.users_id;
    }

    mutate({ url, data: dataToSend, method });
  };

  const onErrorCall = (error) => {
    setOpen(false);
    notification.handleParams(
      error?.response?.data?.data || error.message,
      "error"
    );
  };

  const onSuccessFulCall = (data) => {
    if (data.status === 201) {
      notification.handleParams(
        "Refund request successfully submitted",
        "success"
      );
    } else if (data?.config?.method === "put") {
      notification.handleParams("Refund request updated", "success");
    } else {
      notification.handleParams("Refund deleted", "success");
    }
    queryClient.invalidateQueries(queryKey);
    reset();
    setOpen(false);
  };

  const { mutate, isLoading, isError, data } = usePost(
    onSuccessFulCall,
    onErrorCall
  );

  const handleDelete = (id) => {
    mutate({ url: `refunds/${id}`, data: {}, method: "delete" });
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return {
    open,
    isLoading,
    handleDelete,
    handleOpen,
    handleClose,
    statusOptions,
    state: {
      register,
      errors,
      open,
      notification,
    },
    handlers: {
      handleSubmit,
      onSubmit,
      handleDelete,
      handleClose,
    },
    fetchResponse: {
      isError,
      data,
    },
  };
}

export default useModalLogic;
