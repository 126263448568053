import Button from "@mui/material/Button";
import { green } from "@mui/material/colors";
import { CircularProgress, Box } from "@mui/material";
import Notification from "../Notification";
import useLoginLogic from "./useLoginLogic";
import { PasswordInput, TextInput } from "../Form/FormsInputs";
import classes from "./login.module.css";
import { Link } from "react-router-dom";

function LoginForm() {
  const { state, handlers, fetchResponse } = useLoginLogic();
  const { register, errors, open, notificationMessage } = state;
  const { isLoading } = fetchResponse;
  const { handleSubmit, onSubmit, handleClose } = handlers;

  return (
    <Box
      component="form"
      id="loginForm"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      sx={{ mt: 1 }}
      className={classes.container}
    >
      <div className={classes.main}>
        <div>
          <span className={classes.signin}>Sign in</span>
          <br />
          <span>Enter your credentials to proceed</span>
        </div>
        <TextInput
          register={register}
          name="username"
          id="username"
          label="Username*"
          error={errors?.username}
          autoFocus
        />
        <PasswordInput
          register={register}
          name="password"
          id="password"
          label="Password"
          error={errors?.password}
        />
        <Link to={"reset-password"}>Forgot password?</Link>

        <Button
          type="submit"
          fullWidth
          sx={{ mt: 3, mb: 2 }}
          disabled={isLoading}
          className={classes.button}
        >
          Login
        </Button>

        {isLoading && (
          <CircularProgress
            size={24}
            sx={{
              color: green[500],
              position: "absolute",
              top: "64%",
              left: "67%",
            }}
          />
        )}
        <Notification
          severity="error"
          message={notificationMessage}
          openNotification={open}
          handleClose={handleClose}
        />
      </div>
    </Box>
  );
}

export default LoginForm;
