import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useModalLogicfrom from "./useModalLogic";
import classes from "../modal.module.css";
import { Box } from "@mui/material";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import {
  NumberInput,
  SelectInput,
  TextAreaInput,
  TextInput,
} from "../../Form/FormsInputs";
//
function AddRefund({ queryKey, departmentOptions }) {
  const { state, handlers, handleOpen, isLoading } =
    useModalLogicfrom(queryKey);
  const { register, errors, open } = state;

  const { handleSubmit, onSubmit, handleClose } = handlers;

  return (
    <>
      <Button className={classes.button} onClick={handleOpen}>
        Refund
        <CurrencyExchangeIcon />
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="add-refund-title"
        aria-describedby="add-refund-description"
      >
        <Box
          component="form"
          id="addrefundform"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 1 }}
          className={classes.container}
        >
          <DialogTitle id="add-refund-title">Request for refund</DialogTitle>
          <DialogContent>
            <TextInput
              register={register}
              name="item"
              label="Name of item*"
              id="item"
              error={errors?.item}
              autoFocus
            />
            <NumberInput
              register={register}
              name="amount"
              id="amount"
              minLength={1}
              label="Amount*"
              error={errors?.amount}
            />
            <SelectInput
              register={register}
              name="department_id"
              id="department_id"
              label="Department"
              options={departmentOptions}
            />
            <TextAreaInput
              register={register}
              name="description"
              id="description"
              label="Description*"
              error={errors?.description}
            />
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              disabled={isLoading}
              className={classes.button}
            >
              Proceed&nbsp;
              <CurrencyExchangeIcon />
            </Button>
            <Button onClick={handleClose} autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}

export default AddRefund;
