import { useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { usePost } from "../../../hooks/usePost";
import { useUserContext } from "../../../context/context";

const statusOptions = [
  { value: 1, label: "Active" },
  { value: 0, label: "Inactive" },
];

export const adminOptions = [
  { value: 1, label: "Yes" },
  { value: 0, label: "No" },
];
function useUserModalLogic(rowData = {}) {
  const {
    id,
    fullname = "",
    msisdn = "",
    email = "",
    status = 1,
    is_admin = 0,
  } = rowData;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      fullname,
      msisdn,
      email,
      status,
      is_admin,
    },
  });

  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const { notification } = useUserContext();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onErrorCall = (error) => {
    notification.handleParams(
      error?.message || "Error occurred. Contact admin",
      "error"
    );
  };

  const onSuccessFulCall = (data) => {
    if (data.status === 201) {
      notification.handleParams("User account successfully created", "success");
    } else if (data?.config?.method === "put") {
      notification.handleParams("Account successfully updated", "success");
    } else {
      notification.handleParams("Account successfully deleted", "success");
    }
    queryClient.invalidateQueries([`user/all`]);
    reset();
    setOpen(false);
  };
  const { mutate, isLoading, isError, data } = usePost(
    onSuccessFulCall,
    onErrorCall
  );

  const handleDelete = (id) => {
    mutate({ url: `user/${id}`, data: {}, method: "delete" });
  };

  const onSubmit = (values, event) => {
    let method = "post";
    let url = "user";
    const { username, fullname, email, msisdn, is_admin, password } = values;
    const dataToSend = {
      username,
      fullname,
      email,
      msisdn,
      is_admin,
      password: password !== "" ? password : undefined,
    };
    if (event.target.id === "edituserform") {
      method = "put";
      url = `user/${id}`;
    }
    mutate({ url, data: dataToSend, method });
  };

  return {
    state: {
      register,
      errors,
      open,
      notification,
    },

    handlers: {
      handleSubmit,
      onSubmit,
      handleClickOpen,
      handleDelete,
      handleClose,
    },
    fetchResponse: {
      isLoading,
      isError,
      data,
    },
    statusOptions,
    adminOptions,
  };
}

export default useUserModalLogic;
