import { useState } from "react";
import moment from "moment";
import DeleteRefundRecord from "../../components/Modal/Refunds/DeleteRefundRecord";
import ViewRefundRecord from "../../components/Modal/Refunds/ViewRefundRecord";
import { useUserContext } from "../../context/context";
import { useFetch } from "../../hooks/useFetch";
import classes from "./refund.module.css";
import EditRefund from "../../components/Modal/Refunds/EditRefund";

//
export function useRefundLogic() {
  const { notification, userdetails } = useUserContext();
  const [enable, setEnable] = useState(false);
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "week").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [duration, setDuration] = useState(`${startDate} - ${endDate}`);

  const getFromAndToDate = ({ fromDate, toDate, duration }) => {
    setStartDate(moment(fromDate).format("YYYY-MM-DD"));
    setEndDate(moment(toDate).format("YYYY-MM-DD"));
    setDuration(
      `${moment(fromDate).format("MMMM D, YYYY")} - ${moment(toDate).format(
        "MMMM D, YYYY"
      )}`
    );
    setEnable(false);
  };

  const onSuccess = (_data) => {
    // setEnable(false);
  };

  const onError = (_error) => {
    setEnable(false);
  };

  const queryKey = [
    userdetails.is_admin === 1
      ? `refunds/all?start=${startDate}&end=${endDate}`
      : `refunds?start=${startDate}&end=${endDate}`,
  ];

  const queryResult = useFetch(
    queryKey,
    enable,
    {},
    1800000,
    1800000,
    onSuccess,
    onError
  );

  const onDepartmentError = (error) => {
    notification.handleParams("Error fetching departments", "error");
  };

  const departmentOptionsResult = useFetch(
    "department",
    true,
    {},
    1800000,
    1800000,
    () => null,
    onDepartmentError
  );

  const handleRefundFetch = () => {
    setEnable(true);
  };

  //
  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      name: "username",
      label: "Username",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "department",
      label: "Department",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "item",
      label: "Item",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        sort: true,
        filter: true,
        customBodyRender: (value) => {
          if (value.toLowerCase() === "pending") {
            return <div className={classes.pending}> {value} </div>;
          } else if (value.toLowerCase() === "processing") {
            return <div className={classes.processing}> {value}</div>;
          } else if (value.toLowerCase() === "completed") {
            return <div className={classes.completed}> {value}</div>;
          }
          return <div className={classes.rejected}> {value} </div>;
        },
      },
    },
    {
      name: "time_created",
      label: "Requested Time",
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      name: "time_updated",
      label: "Time Updated",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "image",
      label: "Image",
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        sort: false,
        customBodyRender: (_value, tableMeta, _updateValue) => {
          return (
            <div>
              <ViewRefundRecord rowData={tableMeta.rowData} />
              <EditRefund
                rowData={tableMeta.rowData}
                queryKey={queryKey}
                is_admin={userdetails.is_admin}
                departmentOptions={
                  departmentOptionsResult.data?.data?.data || []
                }
              />
              <DeleteRefundRecord
                rowData={tableMeta.rowData}
                queryKey={queryKey}
                is_admin={userdetails.is_admin}
              />
            </div>
          );
        },
      },
    },
  ];

  return {
    notification,
    getFromAndToDate,
    columns,
    refundRecords: queryResult.data?.data?.data || [],
    isSuccess: queryResult.isSuccess,
    isError: queryResult.isError,
    isLoading: queryResult.isLoading,
    error: queryResult.error,
    handleRefundFetch,
    duration,
    queryKey,
    departmentOptions: departmentOptionsResult.data?.data?.data || [],
  };
}
